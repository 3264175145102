// chakra-ui
import { Button, Flex, Heading, Image, Stack, Text, keyframes, Box } from '@chakra-ui/react';

// icons and images
import { IconBrandX } from '@tabler/icons-react';

import pfp from '../../src/assets/images/pfp.jpg';
import { useEffect, useRef } from 'react';

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const textHover = keyframes`
  0% { transform: scale(1); color: silver; }
  50% { transform: scale(1.05); color: purple; }
  100% { transform: scale(1); color: silver; }
`;

function HomePage()
{
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() =>
    {
        const canvas = canvasRef.current!;
        // @ts-ignore
        const ctx = canvas.getContext('2d')!;

        const resizeCanvas = () =>
        {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        };

        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);

        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$%^&*()_+';
        const fontSize = 10;
        const columns = canvas.width / fontSize;

        const drops: number[] = [];
        for (let i = 0; i < columns; i++)
        {
            drops[i] = 1;
        }

        function draw()
        {
            // @ts-ignore
            ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
            // @ts-ignore
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // @ts-ignore
            ctx.fillStyle = '#0F0';
            // @ts-ignore
            ctx.font = `${fontSize}px monospace`;

            for (let i = 0; i < drops.length; i++)
            {
                const text = characters[Math.floor(Math.random() * characters.length)];
                // @ts-ignore
                ctx.fillText(text, i * fontSize, drops[i] * fontSize);

                if ((drops[i] * fontSize > canvas.height) && (Math.random() > 0.975))
                {
                    drops[i] = 0;
                }
                drops[i]++;
            }
        }

        const intervalId = setInterval(draw, 33);

        return () =>
        {
            clearInterval(intervalId);
            window.removeEventListener('resize', resizeCanvas);
        };
    }, []);

    return (
        <Box position="relative" overflow="hidden" display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <canvas
                ref={canvasRef}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0.1,
                    pointerEvents: 'none',
                }}
            />
            <Stack direction={{ base: 'column', md: 'row' }} bg="black" id="home" maxW="6xl" spacing={8} p={8}>
                <Flex flex={1} justifyContent="center" alignItems="center">
                    <Image 
                        boxSize={{ base: 300, md: 400 }}
                        alt="Lorem Ipsum Profile Picture" 
                        objectFit="cover" 
                        src={pfp} 
                        borderRadius="full" 
                        animation={`${fadeIn} 1s ease-in`}
                        _hover={{ transform: 'rotate(5deg)' }}
                        transition="transform 0.3s ease-in-out"
                    />
                </Flex>
                <Flex flex={1} align="center">
                    <Stack spacing={6} w="full" maxW="xl" animation={`${fadeIn} 1s ease-in`}>
                        <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }} color="silver">
                            <Text 
                                as="span" 
                                position="relative"
                                _hover={{ animation: `${textHover} 0.5s ease-in-out` }}
                            >
                                Kyouki
                            </Text>
                            <br />
                            <Text 
                                fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }} 
                                color="gray.500"
                            >
                                @kyoukirai
                            </Text>
                        </Heading>
                        <Text fontSize={{ base: 'md', lg: 'lg' }} color="silver">
                            existential void yapper; wannabe famous shitposter; (bad) opinions on tech things
                        </Text>
                        <Text fontSize={{ base: 'sm', lg: 'md' }} color="silver">
                            College student (I have learned nothing; teach yourself); SWE Intern; Bad Opinion Haver;
                        </Text>
                        <Text fontSize={{ base: 'md', lg: 'lg' }} color="silver">
                            I'll probably make a blog at some point, but for now just read my tweets.
                        </Text>
                        <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                            <Button as="a" href="https://x.com/kyoukirai" leftIcon={<IconBrandX />} rounded="full" color="silver" _hover={{ color: 'purple', animation: `${pulse} 0.5s infinite` }} _active={{ transform: 'scale(0.95)' }}>
                                Every Thought I have, in a Nutshell
                            </Button>
                        </Stack>
                    </Stack>
                </Flex>
            </Stack>
        </Box>
    );
}

export default HomePage;