
// react
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// pages
import HomePage from './pages/HomePage';

function Router()
{
    const routes = [
        { path: '/', element: <HomePage /> },

    ];

    const router = createBrowserRouter(routes);

    return <RouterProvider router={router} />;
}

export default Router;
