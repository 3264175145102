// chakra-ui
import { ChakraProvider, Box, Container } from "@chakra-ui/react";

// helmet
import { HelmetProvider } from 'react-helmet-async';

// root components
import theme from "./theme.ts";

// custom components
import Router from './Router.tsx';

function App() 
{
    return (
        <HelmetProvider>
            <ChakraProvider theme={theme}>
                <Box bg="black">
                    <Container maxW="12xl" maxH="40vh">
                        <Router />
                    </Container>
                </Box>
            </ChakraProvider>
        </HelmetProvider>
    );
}

export default App;